import Vue from "vue";
import Vuex from "vuex";
import themer from "@xtreme-vue-utils/themer";
import authentication from "@/store/modules/authentication";
import _ from "lodash";

Vue.use(Vuex);

function getWeekDays() {
    const weekdayNames = ["Sun", "Mon", "Tues", "Weds", "Thurs", "Fri", "Sat"];
    const week = [];

    const currentDay = new Date();
    currentDay.setDate(currentDay.getDate()); // Starting from yesterday

    for (let i = 0; i < 7; i += 1) {
        const dayIndex = new Date(currentDay).getDay();
        week.unshift(weekdayNames[dayIndex]);
        currentDay.setDate(currentDay.getDate() - 1);
    }

    return week;
}

export const storeModel = {
    state: {
        feedbackForm: {
            forms: [],
            ordersCollected: 0,
        },
        analytics: {
            lockerStats: {},
            userStats: {},
            timeToServe: {},
            dailyUsage: {
                labels: getWeekDays(),
                datasets: [
                    {
                        label: "Orders",
                        backgroundColor: "#000000",
                        data: [],
                    },
                    {
                        label: "Collected",
                        backgroundColor: "#000000",
                        data: [],
                    },
                ],
            },
            ordersLoaded: 0,
            ordersCollected: 0,
            dwell: [],
            utilisation: [],
            tot: {
                total: {
                    value: 0,
                    label: "Total",
                },
                detail: [],
            },
            rotation: {
                total: {
                    value: 0,
                    label: "Total",
                },
                detail: [],
            },
        },
        config: {},
        lockerStates: [],
        users: [],
        // This can contain any of the report types, we may need to structure this to make it work good when we add additional pages. Hopefully we can rename
        // AgeVerification.vue to Reports and make it dynamically get the reports it needs and updates the headers accoring to the report type provided.
        reports: [],
        orders: [],
        availableLocations: [],
        defaultLocation: {},
        locations: [],
        features: [],
        date: {},
    },
    mutations: {
        updateAnalytics(state, data) {
            if (data?.lockerStats) {
                // prevent dashboard stats from being updated by weeklyutliszation page
                state.analytics.lockerStats = data?.lockerStats;
                state.analytics.userStats = data?.userStats;
            }
            state.analytics.timeToServe = data?.timeToServe;

            state.analytics.dailyUsage.datasets[0].data = data?.dailyUsage?.orders;
            state.analytics.dailyUsage.datasets[1].data = data?.dailyUsage?.collected;

            state.analytics.ordersLoaded = data?.ordersLoaded;
            state.analytics.ordersCollected = data?.ordersCollected;

            state.analytics.dwell = data?.dwell;
            state.analytics.utilisation = data?.utilisation;
            state.analytics.tot = data?.tot;
            state.analytics.rotation = data?.rotation?.total?.value;
        },

        updateFeedbackForm(state, data) {
            state.feedbackForm.forms = data[0]?.forms;
            state.feedbackForm.ordersCollected = data[0]?.ordersCollected;
        },

        updateReports(state, data) {
            state.reports = data;
        },

        updateConfig(state, data) {
            state.config = data;
        },

        updateOrders(state, data) {
            const result = _.cloneDeep(data);

            for (const order of result) {
                if (order.cleared) {
                    order.orderState = "Cleared";
                } else if (order.returns) {
                    order.orderState = "Returned";
                } else if (order.expired) {
                    order.orderState = "Expired";
                } else if (order.collected) {
                    order.orderState = "Collected";
                } else {
                    order.orderState = "Pending";

                    const expireDate = new Date(order.orderDate);
                    expireDate.setDate(expireDate.getDate() + 7);

                    const difference = expireDate.getTime() - new Date().getTime();
                    order.expireTime = Math.ceil(difference / (1000 * 3600 * 24));
                }
            }
            state.orders = result;
        },

        updateUsers(state, data) {
            let index;
            if (data.length === 1) {
                index = state.users.findIndex((u) => u.uid === data[0].uid);

                if (index !== -1) {
                    state.users = state.users.map((user, i) => (i === index ? data[0] : user));
                    return;
                }
                state.users = [...state.users, data[0]];
                return;
            }

            state.users = data;
        },

        updateAvailableLocations(state, data) {
            const superUsers = data.filter((u) => u?.info?.accessLevel === 0 && u?.info?.locations);

            const uniqueLocations = [];

            superUsers.forEach((u) => {
                const { locations } = u.info;
                const values = uniqueLocations.map((v) => v.value);
                locations.forEach((l) => {
                    if (!values.includes(l.value)) {
                        uniqueLocations.push(l);
                    }
                });
            });
            state.availableLocations = uniqueLocations;
        },

        updateLockers(state, updatePackets) {
            if (!updatePackets || (updatePackets && updatePackets[0] === null)) return;

            for (const locker of updatePackets) {
                const index = state.lockerStates.findIndex((l) => l?.id === locker.id);

                if (index === -1) {
                    Vue.set(state.lockerStates, state.lockerStates.length, locker);
                } else {
                    Vue.set(state.lockerStates, index, locker);
                }
            }
        },

        clearLockers(state) {
            state.lockerStates = [];
        },

        updateFeatures(state, features) {
            for (const feature of features) {
                state.features.push(feature);
            }
        },
        clearAllFeatures(state) {
            state.features = [];
        },
        updateDefaultLocation(state, location) {
            state.defaultLocation = location;
        },
        updateLocations(state, locations) {
            state.locations = locations;
        },
        updateDate(state, date) {
            state.date = date;
        },
    },
    actions: {
        receivedFeedback({ commit }, msg) {
            if (!msg?.data?.[0]) {
                return;
            }
            switch (msg.type) {
                case "analytics":
                    commit("updateAnalytics", msg.data[0]);
                    break;
                case "configurations":
                    commit("updateConfig", msg.data[0]);
                    break;
                case "feedback":
                    commit("updateLockers", msg.data);
                    break;
                case "users":
                    commit("updateUsers", msg.data);
                    commit("updateAvailableLocations", msg.data);
                    break;
                case "reports":
                    if (msg?.meta?.reportType === "orderReport") {
                        commit("updateOrders", msg.data);
                    } else if (msg?.meta?.reportType === "surveyReport") {
                        commit("updateFeedbackForm", msg.data);
                    } else {
                        commit("updateReports", msg.data);
                    }
                    break;
                default:
                    break;
            }
        },

        setupLocations({ commit, state, dispatch }) {
            const info = state.authentication.xAuth?.user?.info;
            let location = info?.location;
            if (location) {
                commit("updateDefaultLocation", location);
            }
            if (info?.locations) {
                commit("updateLocations", info?.locations);
                if (!location) {
                    location = info?.locations[0];
                    commit("updateDefaultLocation", location);
                    dispatch("updateUserLocation");
                }
            }
        },

        changeLocation({ commit, dispatch }, { location }) {
            commit("updateDefaultLocation", location);
            dispatch("updateUserLocation");
        },

        updateUserLocation({ dispatch, getters, state }) {
            const user = state.authentication.xAuth?.user;
            dispatch("authentication/send", {
                meta: {
                    location: getters.location,
                    method: "updateLocation",
                },
                type: "users",
                action: "update",
                data: [{
                    ...user,
                    info: {
                        ...user?.info,
                        location: getters.locationObj,
                    },
                }],
            });
        },

        updateLocker({ commit }, lockerInfo) {
            commit("updateLockers", lockerInfo);
        },

        clearLockerStates({ commit }) {
            commit("clearLockers");
        },

        getConfig({ dispatch, getters }) {
            dispatch("authentication/send", {
                meta: {
                    location: getters.location,
                },
                type: "configurations",
                action: "get",
            });
        },

        getAnalytics({ dispatch, getters }, date) {
            dispatch("authentication/send", {
                meta: {
                    startDate: date?.startDate || getters.date?.startDate,
                    endDate: date?.endDate || getters.date?.endDate,
                    location: getters.location,
                    id: "*",
                },
                type: "analytics",
                action: "get",
            });
        },

        getFeedback({ dispatch, getters }) {
            dispatch("authentication/send", {
                meta: {
                    location: getters.location,
                },
                type: "feedback",
                action: "get",
            });
        },

        getReport({ dispatch, getters }, { startDate, endDate, data }) {
            dispatch("authentication/send", {
                meta: {
                    id: "*",
                    location: getters.location,
                    startDate: startDate || getters.date?.startDate,
                    endDate: endDate || getters.date?.endDate,
                },
                type: "reports",
                action: "get",
                data,
            });
        },

        unlockLocker({ dispatch, getters }, { id }) {
            dispatch("authentication/send", {
                type: "commands",
                action: "run",
                meta: {
                    location: getters.location,
                    id,
                },
                data: [
                    {
                        name: id,
                        door: "OpenInstant",
                    },
                ],
            });
        },

        updateOrder({ dispatch, getters }, { orderNumber, id, data }) {
            dispatch("authentication/send", {
                meta: {
                    location: getters.location,
                    id: id || "*",
                },
                type: "orders",
                action: "update",
                data: [
                    {
                        orderNumber,
                        by: getters.userEmail,
                        ...data,
                    },
                ],
            });
        },
        updateSettingsAction({ dispatch, getters }, { data }) {
            dispatch("authentication/send", {
                type: "configurations",
                action: "update",
                data,
                meta: {
                    location: getters.location,
                    "order collection window": "7d",
                    "remove access": "15m",
                },
            });
        },

        userCreateGuest({ dispatch, getters }, { uid, id, code, age }) {
            dispatch("authentication/send", {
                meta: {
                    location: getters.location,
                },
                type: "users",
                action: "create",
                data: [
                    {
                        uid,
                        info: {
                            type: "guest",
                            id,
                            code,
                            age,
                        },
                    },
                ],
            });
        },

        updateReportsAction({ commit }, data) {
            commit("updateReports", data);
        },
        updateOrdersAction({ commit }, data) {
            commit("updateOrders", data);
        },
        updateFeedbackFormAction({ commit }, data) {
            commit("updateFeedbackForm", data);
        },
        updateAnalyticsAction({ commit }, data) {
            commit("updateAnalytics", data);
        },

        formSubscribe({ dispatch, getters }, { orderSubscription, notificationsEmail }) {
            const { location } = getters;
            const locations = {};
            locations[location] = { orderSubscription };

            dispatch("authentication/send", {
                type: "forms",
                action: "create",
                meta: {},
                data: [{
                    locations,
                    notificationsEmail,
                }],
            });
        },

        subscribeToUsers({ dispatch }) {
            dispatch("authentication/send", {
                type: "users",
                action: "get",
                meta: {
                    location: "*",
                },
            });
        },

        unSubscribeToUsers({ dispatch }) {
            dispatch("authentication/send", {
                type: "users",
                action: "unsubscribe",
                meta: {
                    location: "*",
                },
            });
        },

        // duplicated from old code, no idea if this even does anything
        unSubscribeToAll({ dispatch }) {
            dispatch("authentication/send", {
                action: "unsubscribe",
                meta: {
                    location: "*",
                },
            });
        },

        clearStore({ dispatch }) {
            dispatch("clearLockerStates");
            dispatch("updateAnalyticsAction", undefined);
            dispatch("updateFeedbackFormAction", [{
                forms: [],
                ordersCollected: 0,
            }]);
            dispatch("updateOrdersAction", []);
            dispatch("updateReportsAction", []);
        },

        storeDate({ commit }, date) {
            commit("updateDate", date);
        },

        addFeature({ commit }, features) {
            commit("updateFeatures", features);
        },

        clearFeatures({ commit }) {
            commit("clearAllFeatures");
        },
    },
    getters: {
        userEmail: (state) => state.authentication.xAuth?.user?.email,
        location: (state) => state.defaultLocation?.value,
        locationObj: (state) => state.defaultLocation,
        user: (state) => (userId) => state.users.find((u) => u.uid === userId) || {},
        includesFeature: (state) => (val) => state.features.includes(val),
        getFeatures: (state) => state.features,
        date: (state) => state.date,
    },
    modules: {
        authentication,
        themer,
    },
};

const store = new Vuex.Store(storeModel);

store.watch(
    (state) => state.themer.theme,
    (theme) => {
        store.state.analytics.dailyUsage.datasets[0].backgroundColor = theme.colors.primary;
        store.state.analytics.dailyUsage.datasets[1].backgroundColor = theme.colors.secondary;
    },
);

export default store;
