import XAuth from "@xtreme-controls/xtreme-authentication";

export default {
    namespaced: true,
    state: () => ({
        xAuth: undefined,
        connected: false,
    }),
    mutations: {
        updateXAuth(state, xAuth) {
            state.xAuth = xAuth;
        },

        connected(state) {
            state.connected = true;
        },

        disconnected(state) {
            state.connected = false;
        },
    },
    actions: {
        initialise({ state, dispatch, commit }) {
            if (state.xAuth) {
                return;
            }

            const { hostname } = window.location;
            const wsPort = process.env.VUE_APP_WS_PORT || process.env.WS_PORT;

            // Adds a port for testing on localhost
            let wsHost = `${hostname}/ws/app/`;
            if (wsPort) {
                wsHost = `${hostname}:${wsPort}/ws/app/`;
            }

            const xAuthInstance = new XAuth(wsHost, { requireSmsVerification: true }, (msg) => {
                dispatch("receivedFeedback", msg, { root: true });
            });

            xAuthInstance.on("logged-out", () => {
                dispatch("logout");
                commit("disconnected");
            });

            xAuthInstance.on("connected", () => {
                dispatch("setupLocations", {}, { root: true });
                commit("connected");
            });

            commit("updateXAuth", xAuthInstance);
        },

        async login({ state }, { username, password }) {
            if (await state.xAuth.loggedIn()) {
                return state.xAuth.user;
            }

            return state.xAuth.login(username, password);
        },

        async logout({ state }) {
            await state.xAuth.logout();
        },

        async send({ state, dispatch }, msg) {
            if (!state.xAuth) {
                dispatch("initialise");
                await new Promise((resolve) => { setTimeout(resolve, 750); });
            }
            return state.xAuth.send(msg);
        },
    },
    getters: {
        accessLevel(state) {
            const unauthorisedNewUser = 9;
            const accessLevel = state.xAuth?.user?.accessLevel;
            if (!Number.isInteger(accessLevel)) {
                return unauthorisedNewUser;
            }
            return accessLevel;
        },
    },
};
