<template>
    <v-app :style="{ background: $vuetify.theme.themes.dark.background }">
        <x-ybug v-if="accessLevel < 2" id="nycj1wtp9cw8hxzavtq7" />
        <v-app-bar v-if="!hideNav" :style="{ background: $vuetify.theme.themes.dark.darkBackground }" elevation="0" class="dash-app-bar" app>
            <v-app-bar-nav-icon data-test="app-bar-btn" @click.stop="showNavMenu = !showNavMenu" />
            <v-toolbar-title>Clix Dashboard</v-toolbar-title>
            <LocationSwitcher v-if="accessLevel < 3 && locations.length > 1" class="mx-3" />
            <v-spacer />
            <v-btn text data-test="btn-logout" @click="doLogout"> Logout </v-btn>
        </v-app-bar>

        <v-navigation-drawer v-if="!hideNav" v-model="showNavMenu" :style="{ background: $vuetify.theme.themes.dark.darkBackground }" data-test="nav-drawer" app>
            <v-list-item-title class="text-h6 px-5 pt-10">
                <v-img :src="require(`@/assets/default/vos-logo.png`)" alt="logo" />
            </v-list-item-title>
            <v-list-item-subtitle class="pb-10 text-center">
                <span>Smart Lockers, Made Smarter</span>
            </v-list-item-subtitle>
            <v-divider></v-divider>
            <v-list>
                <v-list-item v-for="navItem in navItems" :key="navItem.name" :to="navItem.path" :data-test="`nav-${navItem.name}`" link>
                    <v-list-item-icon>
                        <v-icon>{{ navItem.meta.menu.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ navItem.meta.menu.text }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-spacer />
            <div id="version">Version {{ appVersion }}</div>
        </v-navigation-drawer>

        <v-main>
            <router-view />
        </v-main>
    </v-app>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import LocationSwitcher from "@/components/LocationSwitcher.vue";
import featureCheck from "@/mixins/featureCheck";
import themes from "./themes.json";
import packageJson from "../package.json";

const { version } = packageJson;

export default {
    components: {
        LocationSwitcher,
    },
    mixins: [featureCheck],
    data() {
        return {
            showNavMenu: true,
            appVersion: version,
        };
    },
    computed: {
        ...mapState("authentication", ["xAuth"]),
        ...mapState(["locations"]),
        ...mapGetters("authentication", ["accessLevel"]),
        ...mapGetters(["getFeatures"]),

        navItems() {
            const routes = this.$router?.getRoutes().filter((r) => {
                const isMenuItem = r?.meta?.menu && Object.keys(r?.meta?.menu).length > 0;
                const unrestrictedPage = r?.meta?.accessLevel === undefined;
                const hasAccess = (this.xAuth?.user?.info?.accessLevel <= r?.meta?.accessLevel) || process.env.NODE_ENV === "development";
                const featureBlocked = this.getFeatures?.some((item) => item?.toLowerCase()?.includes(r?.name?.toLowerCase()));

                return isMenuItem && (unrestrictedPage || hasAccess) && !featureBlocked;
            });

            return routes?.map((r) => {
                const route = r;
                if (route.name === "profile") {
                    route.path = route.path.replace("/:uid", "");
                }
                return route;
            });
        },
        hideNav() {
            const routeName = this.$route?.name;
            const hideNav = this.$route?.meta?.hideNavigation;
            return (hideNav === true || routeName === null);
        },
    },
    created() {
        this.initialise();

        const { hostname } = window.location;

        const hostSplit = hostname.split(".");
        if (hostSplit.length > 2) {
            const index = 0;
            document.title = `${hostSplit[index].toUpperCase()} DASHBOARD`;
        } else {
            document.title = "CLIX DASHBOARD";
        }

        const theme = themes.find((t) => t.domains?.includes(hostname)) || themes.find((t) => t.domains?.includes("default"));
        this.activateTheme({
            $vuetify: this.$vuetify,
            theme,
        });

        if (process.env.NODE_ENV === "test") {
            this.appVersion = "1.0.0";
        }
    },
    mounted() {
        this.featureCheck();
    },
    methods: {
        ...mapActions("themer", ["activateTheme"]),
        ...mapActions("authentication", ["initialise", "logout"]),
        ...mapActions(["receivedFeedback"]),

        async doLogout() {
            await this.logout();
            await this.$router.push({ path: "/login" });
        },
    },
};
</script>

<style>
#version {
    position: absolute;
    bottom: 0px;
    right: 10px;
}

@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;700&display=swap");

.v-application {
  font-family: 'Red Hat Display', sans-serif !important;
}

.theme--light.v-application {
  background: #f4f3f4 !important;
  background-color: #f4f3f4 !important;
}

.dash-app-bar {
    border-bottom: 1px solid;
    border-bottom-color: rgba(255, 255, 255, 0.2) !important;
}

.theme--light .v-btn__content {
    text-shadow: none;
}

.v-sheet.highlight-box,
.theme--dark.v-sheet.highlight-box {
    background-color: rgba(150, 150, 150, 0.1);
    border-radius: 6px;
    padding: 20px;
}

.v-sheet.highlight-box.transparent-bg,
.theme--dark.v-sheet.highlight-box.transparent-bg {
    background-color: transparent;
}
.row.controls-row {
    position: sticky;
    top: 64px;
    border: 1px solid #0085ff;
    border-radius: 4px;
    background-color: #ffffff !important;
    margin: 0 0;
    z-index: 1;
}

.v-menu__content {
    box-shadow: none !important;
}

.row.controls-row > div > div > h1 {
    font-weight: 300 !important;
    font-family: 'Red Hat Display', sans-serif !important;
}

.v-text-field--outlined > .v-input__control > .v-input__slot {
    background-color: #ffffff !important;
}

@media (max-width: 959px) {
    .row.controls-row {
        top: 55px;
    }
}
.v-btn__content {
    text-shadow: 0 0 10px rgb(0 0 0 / 40%);
}
.v-card__title {
    background-color: rgba(0, 0, 0, 0.3);
    margin-bottom: 16px;
}
.v-card__actions {
    background-color: rgba(0, 0, 0, 0.15);
    border-top: 1px solid rgba(0, 0, 0, 0.3);
}
.v-card.theme--light{
    border: 1px solid rgba(0,0,0,0.4);
    box-shadow: none !important;
}
.v-card.theme--dark {
    border: 1px solid rgba(255,255,255,0.1);
}

@keyframes ybugSlideIn {
    0% {
        transform: translateX(-50%) translateY(-100%);
    }
    100% {
        transform: translateX(-50%) translateY(0%);
    }
}

#__ybug-launcher.ybug-launcher--top-middle {
    right: 95px !important;
    left: unset !important;
    animation: ybugSlideIn 1s;
}

.theme--light.v-app-bar.v-toolbar.v-sheet {
    background-color: #f4f3f4 !important;

}
</style>
