import { mapState, mapActions } from "vuex";

export default {
    computed: {
        ...mapState("authentication", ["xAuth"]),
    },
    methods: {
        ...mapActions(["addFeature", "clearFeatures", "setFeedbackFormDelay"]),

        async featureCheck() {
            const location = this.$store?.state?.defaultLocation?.value;

            await this.clearFeatures();
            if (location === "greenwich") {
                this.addFeature(["noAgeVerification"]);
            }
            if (location === "dublin") {
                this.addFeature(["noAgeVerification", "noSurveyResponse", "noOrders"]);
            }
            if ((location === "bq-cardiff" || location === "bq-swindon") && window.location.href.includes("dev")) {
                // remove href check when approved for release
                this.addFeature(["noSurveyResponse"]);
            }
            if (window.location.href.includes("rts.vault")) {
                this.addFeature(["rtsFeature"]);
            }
        },
    },
};
