import Vue from "vue";
import xtremeVC from "@xtreme-controls/vue-components";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import packageJson from "../package.json";

const { version } = packageJson;

Vue.use(xtremeVC);

console.info(`App Version: ${version}`);

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount("#app");
